import localforage from "localforage";
import { cleanup, elem, getConfigForComponent, taskId } from "../../config";
import "./WelcomePageComponent";


const eventAddress = "welcome-resolved";
import '../form-page/FormPage'

/**
 *
 *
 * Factory function for WelcomePage Component
 *
 * @returns {Promise<unknown>}
 *
 */
export default async function WelcomePage(configuration) {

    window.scrollTo(0, 0);

    const {
        fieldName,
        translates,
        fieldset,
        component,
        topVisible = true,
        waveVisible = true,
        logoVisible = true,
        askPermissions = false,
        askLicenceAgreement
    } = configuration;

    console.log(configuration)

    return new Promise((resolve, err) => {

        localforage.getItem(taskId).then((result) => {

            let newResult = result;

            const { logoUrl } = result.config;
            console.log(logoUrl)
            window.addEventListener(eventAddress, (data) => {

                window.onbeforeunload = function () {
                    return "Are you sure you want to leave?";
                };

                if (newResult && newResult.model && fieldName) {
                    newResult.model[fieldName] = data.detail;
                } else {
                    newResult.model.licenceAgreement = data.detail;
                }

                localforage.setItem(taskId, newResult).then((res) => {
                    cleanup(); /* This is workaround due to bug in widget */
                });

                resolve();
            }, { passive: true });


            let welcomePage = document.createElement(component);

            welcomePage.i18n = translates;
            welcomePage.licencePermissions = !!newResult?.model?.[fieldName];
            welcomePage.fieldset = fieldset;

            welcomePage.config = {
                askPermissions: askPermissions, // Camera & Geo
                askLicenceAgreement: askLicenceAgreement, // Licence Agreement
                topVisible: topVisible,
                logo: logoUrl,
                logoVisible: logoUrl !== undefined || logoUrl !== "" ? logoVisible : false,
                waveVisible: waveVisible
            };

            elem.innerHTML = "";
            elem.appendChild(welcomePage);
        });
    });
}
